import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [];

  connect() {
    super.connect();
    this.initializeToggles();
  }

  initializeToggles() {
    const checkboxes = this.element.querySelectorAll('[data-toggle-target]');
    checkboxes.forEach((checkbox) => {
      this.toggleSection({ target: checkbox });
    });
  }

  toggleSection(event) {
    const checkbox = event.target;
    const targetSelectors = checkbox.dataset.toggleTarget.split(',');

    targetSelectors.forEach((selector) => {
      const toggleElement = document.querySelector(selector.trim());
      if (toggleElement) {
        toggleElement.classList.toggle('hidden', !checkbox.checked);
      }
    });

    const inputTargetSelector = checkbox.dataset.inputTarget;
    const inputTarget = document.querySelector(inputTargetSelector);
    if (inputTarget) {
      inputTarget.required = checkbox.checked;
    }
  }
}
