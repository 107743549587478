export function focusOnStoreOrder() {
  $(
    "input[name$='store_order[line_items_attributes][quantity]']:checked",
  ).trigger('focus');
}

export function checkSingleChoice(context) {
  const el = $('.qa-radio');

  el.each((idx) => {
    if ($(el[idx]).prop('checked') === true) {
      $(el[idx])
        .prop('checked', true)
        .prev()
        .addClass('font-bold !border-store-darkgray bg-green');

      activateNextButton(context);
      if (typeof context.enableSubmit === 'function') {
        context.enableSubmit();
      }
      $(el[idx])
        .nextAll('.more-info:first')
        .removeClass('hidden')
        .parent()
        .find('label')
        .addClass('mb-0');
    }
  });
}

export function checkMultiAnswer(context) {
  const el = $('.multi_answer_cb');

  el.each((idx) => {
    if ($(el[idx]).prop('checked') === true) {
      $(el[idx])
        .prop('checked', true)
        .parent('li')
        .addClass('border-store-darkgray font-bold');

      activateNextButton(context);
      if (typeof context.enableSubmit === 'function') {
        context.enableSubmit();
      }
      $(el[idx])
        .nextAll('.more-info:first')
        .removeClass('hidden')
        .addClass('');

      const li = $(el[idx]).parent('li').addClass('mb-0');
      const moreInfo = li.next('.more-info');

      if (moreInfo.length) {
        moreInfo.addClass('mb-0').removeClass('hidden');
      }
    }
  });
}

export function checkHardStops(context) {
  const endResponses = context.endResponseTargets;
  if (!endResponses) return;

  const hasHardStop = Array.from(endResponses).some((label) => {
    const inputId = label.getAttribute('for');
    const input = document.getElementById(inputId);
    return input && input.checked;
  });

  if (!hasHardStop) {
    window.questionnaireHardStop = false;
  }

  return hasHardStop;
}

export function activateNextButton(context) {
  if (typeof context.shouldEnableSectionForm === 'function' && !context.shouldEnableSectionForm()) return;
  const submitBtnEl = document.querySelector('.store-button.submit');
  if (!submitBtnEl) return;
  if (checkHardStops(context)) return;
  submitBtnEl.disabled = false;
}

export function selectOption(labelElement) {
  if (labelElement.nodeName === 'LI') return;
  labelElement.parentNode.getElementsByTagName('input')[0].checked = true;
  labelElement.parentNode.classList.add('border-store-darkgray', 'font-bold');
}

export function deselectOption(labelElement) {
  if (labelElement.nodeName === 'LI') return;
  labelElement.parentNode.getElementsByTagName('input')[0].checked = false;
  labelElement.parentNode.classList.remove(
    'border-store-darkgray',
    'font-bold',
  );
}
