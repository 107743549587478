import ApplicationController from "../application_controller";
import { assignPreQuestionnaireAnswers } from "../utilities/user/keep_pre_questionnaire"

export default class extends ApplicationController {
  static targets = ["formContainer"];

  connect() {
    super.connect();
    assignPreQuestionnaireAnswers(this.formContainerTarget);
  }
}
