export function assignPreQuestionnaireAnswers(form) {
  const answers = localStorage.getItem('questionnaireAnswers') || '{}';
  if (answers.length > 0 && answers !== '{}') {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = 'questionnaire_answers';
    input.value = answers;
    form.appendChild(input);
  }
}
