// eslint-disable-next-line no-unused-vars
import tooltipster from 'tooltipster';
import ApplicationController from '../../application_controller';
import {
  handlePromoError,
  handlePromoSuccess,
} from '../../utilities/order_summary_promo_application';

export default class extends ApplicationController {
  static targets = [
    'variant',
    'subscriptionType',
    'packsPerMonth',
    'monthsCount',
    'promoCodeContainer',
    'promoCode',
    'consent',
    'agreementBtn',
    'otpContainer',
    'otpToggleArrow',
    'summaryTitle',
    'summaryContent',
  ];

  beforeReflex() {
    document.body.classList.add('wait');
    const spinner = document.querySelector('.spinner');
    spinner.classList.remove('invisible', 'opacity-0');
    spinner.classList.add('opacity-90', 'bg-white');
  }

  afterReflex() {
    document.body.classList.remove('wait');
    const spinner = document.querySelector('.spinner');
    spinner.classList.add('invisible', 'opacity-0');
  }

  connect() {
    super.connect();
    this.handleTooltips();

    document.addEventListener('promo-update-success', this.handlePromoSuccess);
    document.addEventListener('promo-update-error', this.handlePromoError);
  }

  disconnect() {
    super.disconnect();

    document.removeEventListener(
      'promo-update-success',
      this.handlePromoSuccess,
    );
    document.removeEventListener('promo-update-error', this.handlePromoError);
  }

  toggleOneTimePurchase() {
    // Disable showing/hiding of OTP sub type if OTP is selected
    if (
      this.otpContainerTarget.querySelector(
        'input[name="subscription_type_id"]',
      ).checked
    ) {
      return;
    }

    this.otpContainerTarget.classList.toggle('overflow-hidden');

    if (this.otpContainerTarget.style.height === '0px') {
      this.otpContainerTarget.style.height = '125px';
    } else {
      this.otpContainerTarget.style.height = '0px';
    }
    this.otpToggleArrowTarget.classList.toggle('rotate-180');
  }

  toggleRefundSummary() {
    $('.refund-summary').slideToggle();
  }

  selectSubType(event) {
    this.callStimulusAction({
      cb: () => {
        this.stimulate(
          'Store::Cart::OrderSummary#sub_type_update',
          +event.currentTarget.value,
        );
      },
    });
  }

  checkSub(e) {
    e.preventDefault();
    const { monthsCount } = e.target.dataset;

    this.callStimulusAction({
      cb: () => {
        this.stimulate(
          'Store::Cart::Prepayment#show',
          +monthsCount,
          +e.target.dataset.orderId,
        );
      },
    });
  }

  consentChecked() {
    if (this.consentTarget.checked) {
      this.agreementBtnTarget.removeAttribute('disabled');
    } else {
      this.agreementBtnTarget.setAttribute('disabled', true);
    }
  }

  // This is used in conjunction with the Up Sell Modal to change the subscription type to quarterly
  changeSub(e, quarterlySubId = 2) {
    e.preventDefault();
    this.element.modal.closeModal();

    this.callStimulusAction({
      cb: () => {
        this.stimulate(
          'Store::Cart::OrderSummary#sub_type_update',
          quarterlySubId,
        );
      },
    });
  }

  // TODO: Deprecate. Not used anywhere?
  // https://www.heteroclito.fr/modules/tooltipster/
  handleTooltips() {
    const isMobile = window.matchMedia('(max-width: 767px)').matches;

    // dose strength tooltip
    $('.dose-tooltip:not(".tooltipstered")').tooltipster({
      theme: 'rugiet',
      position: isMobile ? 'top' : 'right',
      content: $(
        "<div style='padding: 10px;'><p style='padding-bottom: 4px;'><strong>Cost per troche.</strong></p><p>Rugiet Ready recommends starting</br>with half a troche to see if that's</br>enough to get the job done.</p></div>",
      ),
      trigger: 'custom',
      triggerOpen: {
        mouseenter: true,
        touchstart: true,
      },
      triggerClose: {
        mouseleave: true,
        tap: true,
      },
    });
    // packs per month tooltip
    $('.ppm-tooltip:not(".tooltipstered")').tooltipster({
      theme: 'rugiet',
      position: isMobile ? 'top' : 'right',
      content: $(
        "<div style='padding: 10px;'><p style='padding-bottom: 4px;'><strong>Cost per month.</strong></p><p>You'll be billed for the full price of</br>your plan and the number of doses</br>and shipped, whether it's a 30-Day Plan</br>(Monthly) or 90-Day plan (Quarterly).</p></div>",
      ),
      trigger: 'custom',
      triggerOpen: {
        mouseenter: true,
        touchstart: true,
      },
      triggerClose: {
        mouseleave: true,
        tap: true,
      },
    });
  }

  variantUpdate() {
    const variantId = this.variantTarget.value;
    const subTypeId = this.subscriptionTypeTarget.value;
    const packsPerMonth = this.packsPerMonthTarget.value;

    this.callStimulusAction({
      cb: () => {
        this.stimulate(
          'Store::Cart::OrderSummary#variant_update',
          +variantId,
          +subTypeId,
          +packsPerMonth,
        );
      },
    });
  }

  promoUpdate(e) {
    e.preventDefault();
    const promoCode = this.promoCodeTarget.value;

    this.callStimulusAction({
      cb: () => {
        this.stimulate('Store::Cart::OrderSummary#promo_update', promoCode);
      },
    });
  }

  handlePromoSuccess(event) {
    const { result } = event.detail;
    const promoTextField = document.getElementById('promotion_promo_code');
    const resultTag = document.getElementById('promo-code-result');
    const promoApplyBtn = document.getElementById('promo-apply-btn');
    handlePromoSuccess(result, promoTextField, resultTag, promoApplyBtn);
  }

  handlePromoError(event) {
    const { result } = event.detail;
    const resultTag = document.getElementById('promo-code-result');
    const promoApplyBtn = document.getElementById('promo-apply-btn');
    handlePromoError(result, resultTag, promoApplyBtn);
  }

  togglePromoCode(element) {
    element.preventDefault();
    this.promoCodeContainerTarget.classList.toggle('hidden');
    this.promoCodeContainerTarget.classList.toggle('opacity-0');
    this.promoCodeContainerTarget.classList.toggle('invisible');
  }

  toggleSummaryContent() {
    if (this.hasSummaryContentTarget) {
      const h3Element = this.summaryTitleTarget.querySelector('h3');
      const imgElement = this.summaryTitleTarget.querySelector('img');

      h3Element.classList.toggle('mb-q-16');
      imgElement.classList.toggle('rotate-180');
      imgElement.classList.toggle('mb-[14px]');

      this.summaryContentTarget.classList.toggle('translate-y-1/2');
      this.summaryContentTarget.classList.toggle('max-h-0');
      this.summaryContentTarget.classList.toggle('max-h-[500px]');
      this.summaryContentTarget.classList.toggle('invisible');
      this.summaryContentTarget.classList.toggle('opacity-0');
    }
  }
}
