import ApplicationController from '../../application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
  }

  submit_form(event) {
    event.preventDefault();
    event.target.closest('form').submit();
  }
}
