// app/javascript/controllers/dynamic_fields_controller.js
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'faqsFieldsContainer',
    'highlightsFieldsContainer',
    'faqsTemplate',
    'highlightsTemplate',
  ];

  addField(event) {
    event.preventDefault();

    const templateTargetName = event.target.dataset.target;
    const containerTargetName = event.target.dataset.container;

    const template = this.targets.find(templateTargetName.split('.').pop());
    const container = this.targets.find(containerTargetName.split('.').pop());

    const content = template.innerHTML;
    container.insertAdjacentHTML('beforeend', content);
  }

  removeField(event) {
    event.preventDefault();

    const fieldContainer = event.target.closest('.field-container');
    if (fieldContainer) {
      const destroyField = fieldContainer.querySelector(
        'input[type="hidden"][name*="_destroy"]',
      );
      if (destroyField) {
        // For FAQs, set _destroy to '1' and hide the field
        destroyField.value = '1';
        fieldContainer.style.display = 'none';
      } else {
        // For Highlights, remove the field from the DOM
        fieldContainer.remove();
      }
    }
  }
}
