import ApplicationController from "../application_controller";
import { assignPreQuestionnaireAnswers } from "../utilities/user/keep_pre_questionnaire"

export default class extends ApplicationController {
  static targets = ["container", "email", "validEmail", "emailValidationContainer", "formContainer"];

  processChange = this.debounce((e) => this.checkEmail(e), 3000);

  validateEmailField = _event => {
    if (!this.emailValidationContainerTarget.classList.contains("field_with_errors")) return;

    this.emailValidationContainerTarget.classList.remove("field_with_errors");
  }

  invalidateEmailField = _event => {
    if (this.emailValidationContainerTarget.classList.contains("field_with_errors")) return;

    this.emailValidationContainerTarget.classList.add("field_with_errors");
  }

  connect() {
    super.connect();

    document.addEventListener('keyup', this.processChange)
    document.addEventListener("validEmail", this.validateEmailField);
    document.addEventListener("invalidEmail", this.invalidateEmailField);
    assignPreQuestionnaireAnswers(this.formContainerTarget);
    if (localStorage.getItem('questionnaireAnswers') !== null) {
      $.ajax({
        type: 'GET',
        url: '/progress_bar_width',
        data: {
          questionnaire_answers: localStorage.getItem('questionnaireAnswers'),
          controller_name: 'users/sign_up',
          action_name: 'new'
        },
        success: function(response) {
          const progressPercent = response.progress_bar_width;
          $('.checkout-progress__bar').css('width', progressPercent + '%');
        },
        error: function(error) {
          console.error('Error fetching progress bar width:', error);
        }
      });
    }
  }

  disconnect() {
    super.disconnect();

    document.removeEventListener('keyup', this.processChange)
    document.removeEventListener("validEmail", this.validateEmailField);
    document.removeEventListener("invalidEmail", this.invalidateEmailField);
  }

  validEmailTargetConnected() {
    $(this.validEmailTarget).on("click", (e) => {
      this.validateEmailField(e);
      $(this.emailTarget).val($(this.validEmailTarget).text());
      $(this.containerTarget).remove();
    });
  }

  debounce(func, timeout = 300) {
    let timer;

    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  checkEmail() {
    if (!this.emailTarget.value.length) return;

    $.ajax({
      type:     'POST',
      url:      '/registration_validations',
      data:     { validations: { email: this.emailTarget.value } },
    }).done(function (result) {
      const errorEl  = document.querySelector('#email-validation-error');
      const { html } = result

      if (errorEl) {
        errorEl.innerHTML = html;
      }
    }).fail(function (error) {
      console.error(error)
    })
  }
}
