import ApplicationController from '../application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
  }

  add_association(event) {
    event.preventDefault();
    const { target } = event.currentTarget.dataset;
    const form = event.currentTarget.closest('.subscription-adjustment-form');
    const targetElement = form.querySelector(`template.${target}`);
    const content = targetElement.innerHTML.replace(/NEW_RECORD/g, new Date().getTime());
    form.getElementsByClassName(`${target}-adjustments`)[0].insertAdjacentHTML('beforebegin', content);
  }

  remove_association(event) {
    event.preventDefault();
    const item = event.target.closest('.nested-fields');
    item.querySelector("input[name*='_destroy']").value = 1;
    item.style.display = 'none';
  }

  render_form(event) {
    event.preventDefault();
    const form = event.currentTarget.closest('form');
    this.stimulate(
      'Admin::SubscriptionTypes::FormReflex#render_form',
      event.target.dataset.subscriptionTypeId,
      form.querySelector('#product_id').value,
      form.querySelector('#variant_id').value
    );
  }
}
