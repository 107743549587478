import ApplicationController from '../../application_controller';

export default class extends ApplicationController {
  static targets = ['displayByDoses', 'doseSettings', 'doseLabelContainer'];

  connect() {
    super.connect();
    const isChecked = this.displayByDosesTarget.checked;
    this.toggleDoseSettingsOnLoad(isChecked);
  }

  toggleDoseSettings() {
    const isChecked = this.displayByDosesTarget.checked;
    this.doseSettingsTargets.forEach((label) => {
      label.classList.toggle('required', isChecked);
    });
    this.updateDoseLabelContainer(isChecked);
  }

  toggleDoseSettingsOnLoad(isChecked) {
    this.doseSettingsTargets.forEach((label) => {
      label.classList.toggle('required', isChecked);
    });
    this.updateDoseLabelContainer(isChecked);
  }

  updateDoseLabelContainer(isChecked) {
    const doseLabel = this.doseLabelContainerTarget.querySelector('label');
    this.doseLabelContainerTarget.classList.toggle('hidden', !isChecked);

    if (doseLabel) {
      doseLabel.classList.toggle('required', isChecked);
    }
  }
}
