import ApplicationController from '../../application_controller';

export default class extends ApplicationController {
  static targets = ['ingredientFields', 'container', 'destroyField'];

  connect() {
    super.connect();
  }

  addIngredient(event) {
    event.preventDefault();
    const newField = this.ingredientFieldsTarget.cloneNode(true);
    newField.querySelectorAll('input').forEach((input) => {
      input.value = '';
      input.name = input.name.replace(/\d+/, new Date().getTime());
    });
    this.containerTarget.appendChild(newField);
  }

  removeIngredient(event) {
    event.preventDefault();
    const field = event.currentTarget.closest('.ingredient-fields');
    if (field) {
      const destroyField = field.querySelector(
        "[data-target='admin--forms--medication.destroyField']",
      );
      if (destroyField) {
        destroyField.value = '1';
        field.style.display = 'none';
      }
    }
  }
}
